import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './updateBookForm.module.css'; // Import your CSS if needed
import Notification from '../../generic/notifications/notification.js'; // Import the Notification component
import config from '../../../config.js';

export default function UpdateBookForm() {

    // Initialize state variables for the form fields
    // const [bookId, setBookId] = useState('');

    // Func for book autocompletion
    const [bookAutocomplete, setBookAutocomplete] = useState([]);
    const [selectedBook, setSelectedBook] = useState({
      title: '', // The book's title
      _id: null, // The book's ID
    });

    const [title, setTitle] = useState('');
    // const [author, setAuthor] = useState('');
    const [genre, setGenre] = useState('');
    const [publisher, setPublisher] = useState('');
    const [year, setYear] = useState('');
    const [keywords, setKeywords] = useState('');

    // Func for author autocompletion
    const [authorAutocomplete, setAuthorAutocomplete] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState({
      name: '', // The author's name
      _id: null, // The author's ID
    });

    const createNewAuthor = async () => {
      try {

        // Create a new author object with the provided name
        const newAuthor = { 
          name: selectedAuthor.name,
          date_of_birth: null,
          date_of_death: null,
          ethnicity: "",
          imageUrl: "",
          bio: "",
         };

        // Send a POST request to create the new author
        const response = await axios.post(`${config.API_ENDPOINT}/authors`, newAuthor);
  
        // Update the selectedAuthor with the newly created author's name
        setSelectedAuthor(response.data.name);
  
        // Optionally, you can update the authorAutocomplete state to include the new author
        setAuthorAutocomplete([...authorAutocomplete, response.data]);
      } catch (error) {
        console.error('Error creating new author:', error);
      }
    };

    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    // Fetching list of existing books, and populating the bookAutocomplete state once the component mounts:
    useEffect(() => {
      const fetchBooks = async () => {
        try {
          const response = await axios.get(`${config.API_ENDPOINT}/books`);
          const books = response.data;
          setBookAutocomplete(books);
        } catch (error) {
          console.error('Error fetching books:', error);
        }
      };
    
      fetchBooks();
    }, []);

    // Fetching list of existing authors, and populating the authorAutocomplete state once the component mounts:
    useEffect(() => {
      const fetchAuthors = async () => {
        try {
          const response = await axios.get(`${config.API_ENDPOINT}/authors`);
          const authors = response.data;
          setAuthorAutocomplete(authors);
        } catch (error) {
          console.error('Error fetching authors:', error);
        }
      };
    
      fetchAuthors();
    }, []);
    
    // Handle the update operation when the form is submitted
    const handleBookUpdate = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Create an object with the updated book information
    const updatedBook = {};

    // Check if each field has a value, and include it in the updatedBook object if it does
    if (title) updatedBook.title = title;
    // if (selectedBook._id) updatedBook.title = selectedAuthor._id;
    if (selectedAuthor._id) updatedBook.author = selectedAuthor._id;
    if (genre) updatedBook.genre = genre;
    if (publisher) updatedBook.publisher = publisher;
    if (year) updatedBook.year = year;
    if (keywords) updatedBook.keywords = keywords;

    try {
      // Send a PUT request to update the book data
      const response = await axios.put(`${config.API_ENDPOINT}/books/${selectedBook._id}`, updatedBook);
      const updatedBookData = response.data;
      console.log('Book updated:', updatedBookData);
      
      // Show the notification
      setNotificationMessage('Book updated successfully.');
      setShowNotification(true);

      // Reset form fields
      setBookAutocomplete([]);
      setTitle('');
      setSelectedAuthor({
        name: '',
        _id: null,
      });
      setSelectedBook({
        title: '',
        _id: null,
      });
      setAuthorAutocomplete([]);
      setGenre('');
      setPublisher('');
      setYear('');
      setKeywords('');

    } catch (error) {
      console.error('Error updating book:', error);
      
      setNotificationMessage('An error occurred.');
      setShowNotification(true);
    }

    // Close the notification after a delay
    setTimeout(() => {
      setShowNotification(false);
  }, 3000); // Adjust the delay as needed

  };

  // Render the update book form
  return (
    <div className={styles.updImg}>
      <h2 className={styles.titleText}>Update Book</h2>
        {/* Input fields for various book properties */}
        <label>
          <p className={styles.formText}>Book:</p>
          <input
            type='text'
            list='book-options'
            value={selectedBook.title}
            // To capture changes made by selecting an author from the datalist [as well as manual changes] */}
            onInput={(e) => {

              const selectedBookId = bookAutocomplete.find(
                (book) => book.title === e.target.value
              )?._id;
          
              setSelectedBook({
                title: e.target.value,
                _id: selectedBookId || null, // Use null if book not found
              });

            }}
          />

          <datalist id='book-options' key={bookAutocomplete.length}>
            {/* Mapping through the bookAutocomplete array to generate options */}
            {bookAutocomplete.map((book) => (
              <option key={book._id} value={book.title} />
            ))}
          </datalist>

          <input
            type='hidden'
            name='bookId'
            value={selectedBook._id || ''}
          />
        </label>
        <label>
          <p className={styles.formText}>Title:</p>
          <input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label>
          <p className={styles.formText}>Author:</p>
          <input
            type='text'
            list='author-options'
            value={selectedAuthor.name}
            // To capture changes made by selecting an author from the datalist [as well as manual changes] */}
            onInput={(e) => {

              const selectedAuthorId = authorAutocomplete.find(
                (author) => author.name === e.target.value
              )?._id;
          
              setSelectedAuthor({
                name: e.target.value,
                _id: selectedAuthorId || null, // Use null if author not found
              });

              console.log("selectedAuthor.name: " + selectedAuthor.name);
              console.log("selectedAuthor._id: " + selectedAuthor._id);

            }}
          />

          {/* Datalist for autocompletion */}
          {/* The <datalist> tag specifies a list of pre-defined options for an <input> element.
          The <datalist> tag is used to provide an "autocomplete" feature for <input> elements. Users will see a drop-down list of pre-defined options as they input data.
          The <datalist> element's id attribute must be equal to the <input> element's list attribute (this binds them together). */}
          <datalist id='author-options'>
            {/* Mapping through the authorAutocomplete array to generate options */}
            {authorAutocomplete.map((author) => (
              <option key={author._id} value={author.name} />
            ))}
          </datalist>

          <input
            type='hidden'
            name='authorId'
            value={selectedAuthor._id || ''}
          />
        </label>
        {/* Button to create a new author */}
        <button onClick={createNewAuthor} type="submit">Create New Author</button>
        <label>
          <p className={styles.formText}>Genre:</p>
          <input
            type='text'
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
          />
        </label>
        <label>
          <p className={styles.formText}>Publisher:</p>
          <input
            type='text'
            value={publisher}
            onChange={(e) => setPublisher(e.target.value)}
          />
        </label>
        <label>
          <p className={styles.formText}>Year:</p>
          <input
            type='text'
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </label>
        <label>
          <p className={styles.formText}>Keywords:</p>
          <input
            type='text'
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
        </label>
        <form onSubmit={handleBookUpdate}> {/* onSubmit is an event handler */}
                {/* Submit button */}
                <button type="submit">Update Book</button>
        </form>

      {showNotification && (
                <Notification
                message={notificationMessage}
                onClose={() => setShowNotification(false)}/>         
      )}
            
    </div>
  );
}
