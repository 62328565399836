import React, { useState, useEffect } from 'react';
import axios from 'axios';

import styles from './booklist.module.css';
import config from '../../../config.js'

import '../../../App.css'; //To check if it is actually needed

const Booklist = () => {

  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const fetchBooks = async () => {
    try {
      const response = await axios.get(`${config.API_ENDPOINT}/books?page=${currentPage}&pageSize=${pageSize}`);
      setBooks(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBooks();
  }, [currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className={styles.bookImg}>
    <div className={styles.tableContainer}>
      <h1 className={styles.bookHeader}>Books</h1>
       <table className={styles.table}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Author</th>
            </tr>
          </thead>
          <tbody id="bookTableBody"> 
          {/* 
          Book rows will be dynamically generated here */
          }
          {books.map(book => (
            <tr key={book._id}>
              <td>{book.title}</td>
              <td>{book.author ? book.author.name : ''}</td>
            </tr>
          ))}
          </tbody>
        </table>
        <div className={styles.paginationContainer}>
          <button onClick={handlePreviousPage}>Previous Page</button>
          <span>Page {currentPage}</span>
          <button onClick={handleNextPage}>Next Page</button>
      </div>
    </div>
    </div>
  );
};

export default Booklist;
