import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './deleteAuthorForm.module.css'; 
import config from '../../../config.js';

export default function DeleteAuthorForm() {
  
    const [authors, setAuthors] = useState([]);
    const [selectedAuthors, setSelectedAuthors] = useState([]);
  
    useEffect(() => {
      fetchAuthors();
    }, []);
  
    const fetchAuthors = async () => {
      try {
        const response = await axios.get(`${config.API_ENDPOINT}/authors`);
        setAuthors(response.data);
      } catch (error) {
        // Handle error
      }
    };
  
    const toggleAuthorSelection = (authorId) => {
      if (selectedAuthors.includes(authorId)) {
        setSelectedAuthors(selectedAuthors.filter(id => id !== authorId));
      } else {
        setSelectedAuthors([...selectedAuthors, authorId]);
      }
    };
  
    const handleDeleteSelectedAuthors = async () => {
      try {
        await axios.delete(`${config.API_ENDPOINT}/authors`, {
          data: { authorIds: selectedAuthors }, // Send selected author IDs for deletion
        });
        fetchAuthors(); // Refresh author list after deletion
        setSelectedAuthors([]); // Clear selected authors
      } catch (error) {
        // Handle error
      }
    };
  
    return (
      <div className={styles.oldImg}>
      {/* <p className="image-credits">
      Image by <a href="https://pixabay.com/users/jarmoluk-143740/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=436498">Michal Jarmoluk</a> from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=436498">Pixabay</a>
      </p> */}
        <table>
          <thead>
            <tr className={styles.titleText}>
              <th>Select</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {authors.map(author => (
              <tr key={author._id} className={styles.textRow}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedAuthors.includes(author._id)}
                    onChange={() => toggleAuthorSelection(author._id)}
                  />
                </td>
                <td>{author.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit" onClick={handleDeleteSelectedAuthors}>Delete Selected Authors</button>
      </div>

  )
};