import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBook, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import LogoutButton from './logout/Logout_Button.js';

const Sidebar = () => {

  return (
      <div className='sidebar'>
      <h2> Bibliotheca</h2>
      <LogoutButton />
      <ul>
      <li>
          <Link to="/home">
            <FontAwesomeIcon icon={faHome} /> Home
          </Link>
        </li>
        <li>
          <Link to="/about">
            <FontAwesomeIcon icon={faUser} /> About
          </Link>
        </li>
        <section className="menu">
          <li>
          <span className="expandable">
              <FontAwesomeIcon icon={faBook} /> <Link to="/books">Books</Link>
            </span>
            <ul className="submenu">
              <li> 
                <Link to="/add-book">
                  Add a new book
                </Link>
              </li>
              <li>
                <Link to="/search-book">
                  Search for a book
                </Link>
              </li>
              <li>
                <Link to="/delete-book">
                  Delete a book
                </Link>
              </li>
              <li>
                <Link to="/update-book">
                  Update a book
                </Link>
              </li>
            </ul>
          </li>
        </section>
          <li>
          <span className="expandable">
            <FontAwesomeIcon icon={faUser} /> Authors
          </span>
            <ul className="submenu">
              <li> 
                <Link to="/add-author"> {/* Use <Link> instead of <a> */}
                  Add an author
                </Link>
              </li>
              <li>
                <Link to="/search-author"> {/* Use <Link> instead of <a> */}
                  Search for an author
                </Link>
              </li>
              <li>
                <Link to="/delete-author"> {/* Use <Link> instead of <a> */}
                  Remove an author
                </Link>
              </li>
              <li>
                <Link to="/update-author"> {/* Use <Link> instead of <a> */}
                  Update an author's info
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact">
              <FontAwesomeIcon icon={faEnvelope} /> Contact
            </Link>
          </li>
      </ul>
      </div>
  );
};

export default Sidebar;