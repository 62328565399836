import React, { useState } from 'react';
import axios from 'axios';
import styles from './updateAuthorForm.module.css';
import config from '../../../config.js';

export default function UpdateAuthorForm() {

    const [authorId, setAuthorId] = useState('');
    const [name, setName] = useState('');
    const [date_of_birth, setDoB] = useState(null);
    const [date_of_death, setDoD] = useState('');
    const [ethnicity, setNationality] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [bio, setBio] = useState('');

    // Handle the update operation when the form is submitted
    const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Create an object with the author book information
    const updatedAuthor = {};

    // Check if each field has a value, and include it in the updatedAuthor object if it does
    if (name) updatedAuthor.name = name;
    if (date_of_birth) updatedAuthor.date_of_birth = date_of_birth;
    if (date_of_death) updatedAuthor.date_of_death = date_of_death;
    if (ethnicity) updatedAuthor.ethnicity = ethnicity;
    if (imageUrl) updatedAuthor.year = imageUrl;
    if (bio) updatedAuthor.bio = bio;

    try {
    // Send a PUT request to update the book data
    const response = await axios.put(`${config.API_ENDPOINT}/authors/${authorId}`, updatedAuthor);
    const updatedAuthorData = response.data;
    console.log('Author updated:', updatedAuthorData);
    // Handle success, e.g., display a success message or update state
    } catch (error) {
    console.error('Error updating author:', error);
    // Handle error, e.g., display an error message
    }
};

    return (
        <div className={styles.img}>
            <h2 className={styles.titleText}>Update Author Information</h2>
                <form onSubmit={handleUpdate}>
                    <label>
                    <p className={styles.formText}>Author ID:</p>
                    <input type="text" value={authorId} onChange={(e) => setAuthorId(e.target.value)} /> {/* TO DO: Author piece needs more functionalities, e.g. drop-down lists, create a new author etc */}
                    </label> 
                    <label>
                    <p className={styles.formText}>Author:</p>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} /> {/* TO DO: Author piece needs more functionalities, e.g. drop-down lists, create a new author etc */}
                    </label>
                    <label>
                    <p className={styles.formText}>Birth:</p>
                    <input type="text" value={date_of_birth} onChange={(e) => setDoB(e.target.value)} />
                    </label>
                    <label>
                    <p className={styles.formText}>Death:</p>
                    <input type="text" value={date_of_death} onChange={(e) => setDoD(e.target.value)} />
                    </label>
                    <label>
                    <p className={styles.formText}>Nationality:</p>
                    <input type="text" value={ethnicity} onChange={(e) => setNationality(e.target.value)} />
                    </label>
                    <label>
                    <p className={styles.formText}>Image Url:</p>
                    <input type="number" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                    </label>
                    <label>
                    <p className={styles.formText}>Bio:</p>
                    <input type="text" value={bio} onChange={(e) => setBio(e.target.value)} />
                    </label>
                <button type="submit">Update Author</button>
            </form>
    </div>
  );
};