import React, { useState } from 'react';
import axios from 'axios';
import './contactForm.css';
import config from '../../../config.js';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      // Send a message to your personal email
      const messageData = {
        name,
        email,
        message,
      };
      const messageResponse = await axios.post(`${config.API_ENDPOINT}/contact`, messageData);

      if (messageResponse.status === 200) {
        // If the subscribe checkbox is checked, hit the subscribe endpoint
        if (subscribe) {
          const subscriptionData = { email };
          const subscriptionResponse = await axios.post(`${config.API_ENDPOINT}/users/subscribe`, subscriptionData);

          if (subscriptionResponse.status === 200) {
            setSubmitted(true);
            setName('');
            setEmail('');
            setMessage('');
            setSubscribe(false);
          } else {
            console.error('Failed to subscribe user');
          }
        } else {
          setSubmitted(true);
          setName('');
          setEmail('');
          setMessage('');
        }
      } else {
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='img-background'>
        <h2 className='title-text'>Contact Me</h2>
          <form onSubmit={handleSubmit}>

            <label htmlFor="name">
              <p className="form-text">Name:</p>
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <label htmlFor="email">
              <p className="form-text">Email:</p>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label htmlFor="message">
              <p className="form-text">Message:</p>
            </label>
            <textarea className='contact-textarea'
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>

             {/* Subscription Checkbox */}
            <label className="form-text">
              <input
                type="checkbox"
                checked={subscribe}
                onChange={(e) => setSubscribe(e.target.checked)}
              />
              Subscribe to our newsletter
            </label>

        <button type="submit" disabled={submitting || submitted}>
          {submitting ? 'Submitting...' : submitted ? 'Submitted!' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;