import React, { useState } from 'react';
import axios from 'axios';
import styles from './addAuthorForm.module.css';
import Notification from '../../generic/notifications/notification.js'; // Import the Notification component
import config from '../../../config.js';

export default function AddAuthorForm() {

    const [name, setName] = useState('');
    const [date_of_birth, setDoB] = useState(null);
    const [date_of_death, setDoD] = useState('');
    const [ethnicity, setNationality] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [bio, setBio] = useState('');

    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    //   The handleSubmit function is an asynchronous function that uses axios to make the API call.
    //   The axios.post method sends a POST request to the /api/books endpoint with the newBook object as the request body.

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a new author object with the form data
        const newAuthor = {
        name,
        date_of_birth,
        date_of_death,
        ethnicity,
        imageUrl,
        bio
        };

        try {
        // Make the API call to the backend using axios
        const response = await axios.post(`${config.API_ENDPOINT}/authors`, newAuthor);

        // The response from the server is stored in the response variable, and we can handle the response data as needed. 
        // Here it logs the savedBook data to the console.

        const savedAuthor = response.data;
        console.log(savedAuthor); // Handle the response from the server if needed

        // Show the notification
        setNotificationMessage('Author added successfully.');
        setShowNotification(true);

        // Reset the form inputs
        setName('');
        setDoB('');
        setDoD('');
        setNationality('');
        setImageUrl('');
        setBio('');
        } catch (error) {
            console.error(error);
            // Handle any errors that occur during the API call
            // Show the notification
            setNotificationMessage('An error occurred.');
            setShowNotification(true);
        }

        // Close the notification after a delay
        setTimeout(() => {
            setShowNotification(false);
        }, 3000); // Adjust the delay as needed
    };

    return (
        <div className={styles.img}>
            <h2 className={styles.titleText}>Add an author</h2>
                {/* <div className="container-book-form"> */}
                    {/* <div className="add-book-form"> */}
                <form onSubmit={handleSubmit}> {/* onSubmit is an event handler */}

                {/* 
                type="text": This attribute specifies the type of the input field as "text". It indicates that the input should accept plain text input from the user.

                value={title}: The value attribute sets the current value of the input field. In this case, it is bound to the title state variable. 
                                By setting the value to the title state variable, the input field will display the current value stored in the title state.

                onChange={(e) => setTitle(e.target.value)}: The onChange event handler is triggered when the user changes the value of the input field. 
                                                            In this case, it calls an inline arrow function that takes an event (e) as an argument. 
                                                            Within the event handler, e.target.value retrieves the new value entered by the user in the input field.

                (e) => setTitle(e.target.value): This arrow function updates the title state variable with the new value entered by the user. 
                It calls the setTitle function (provided by the useState hook) and passes in e.target.value as the new value. 
                This will trigger a re-render of the component with the updated title state value.

                So, when the user types something into the input field, the onChange event handler is fired, capturing the new value. 
                The setTitle function is then called with the new value, updating the title state variable. 
                As a result, the input field's value is updated and the component is re-rendered with the new value reflected in the input field.

                This allows us to capture and track the changes made by the user in the title state variable, 
                making it available for further processing or sending to the backend when needed. */}

                {/* Author input */}
                <label>
                <p className={styles.formText}>Author:</p>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} /> {/* TO DO: Author piece needs more functionalities, e.g. drop-down lists, create a new author etc */}
                </label>
                {/* Birth input */}
                <label>
                <p className={styles.formText}>Birth:</p>
                <input type="text" value={date_of_birth} onChange={(e) => setDoB(e.target.value)} />
                </label>
                {/* Death input */}
                <label>
                <p className={styles.formText}>Death:</p>
                <input type="text" value={date_of_death} onChange={(e) => setDoD(e.target.value)} />
                </label>
                {/* Nationality input */}
                <label>
                <p className={styles.formText}>Nationality:</p>
                <input type="text" value={ethnicity} onChange={(e) => setNationality(e.target.value)} />
                </label>
                {/* Image Url input */}
                <label>
                <p className={styles.formText}>Image Url:</p>
                <input type="number" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                </label>
                {/* Bio input */}
                <label>
                <p className={styles.formText}>Bio:</p>
                <input type="text" value={bio} onChange={(e) => setBio(e.target.value)} />
                </label>
                {/* Submit button */}
                <button type="submit">Add Author</button>
            </form>
            {/* </div> */}

            {showNotification && (
                <Notification
                message={notificationMessage}
                onClose={() => setShowNotification(false)}
                />
            )}

        </div>
  );
}
