import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from './searchBookForm.module.css';
import config from '../../../config.js';

export default function SearchBookForm() {
 
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const popupRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery === '') {
        setSearchResults([]); // Clear results if search query is empty
        return;
      }

      try {
        // Fetch data from the backend using the search query
        const response = await axios.get(`${config.API_ENDPOINT}/books/search?q=${searchQuery}`);
        setSearchResults(response.data); // Update search results state
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [searchQuery]); // Trigger useEffect when searchQuery changes

  useEffect(() => {
    // Add event listener to detect clicks on the document
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSearchResults([]); // Close the pop-up when clicking outside
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className={styles.searchImg}>
    {/* <p className="image-credits">
      Image by <a href="https://pixabay.com/users/tuendebede-3187213/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2507902">Tünde</a> from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2507902">Pixabay</a>
      </p> */}
      <div className={styles.autocompleteContainer}>
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {searchResults.length > 0 && (
        <div className={styles.autocompletePopup} ref={popupRef}>
          {searchResults.map((book) => (
            <div key={book._id}>
              <a href={`/book/${book._id}`} target="_blank" rel="noopener noreferrer">
                  {book.title}
              </a>
            </div>
          ))}
        </div>
      )}

    </div>
    </div>
  );
};