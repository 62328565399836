const env = process.env.REACT_APP_ENV || 'development';

const config = {
  development: {
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT,
  },
  test: {
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT_TEST,
  },
  production: {
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT_PRODUCTION,
  },
};

export default config[env];