import React, {useState} from 'react';
import styles from './login.module.css';
import Notification from '../notifications/notification.js'; // Import the Notification component
import axios from 'axios';
import useAuth from '../../../hooks/useAuth.js';
import config from '../../../config.js';
import backgroundImage from '../../../images/library_white.jpg';
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {

  const { auth, setAuth, login } = useAuth(); // Use the login function from the context
  const navigate = useNavigate(); // Define the navigate function
  const location = useLocation();
  const from = location.state?.from?.pathname || "/"; // Get the "from" path from the previous location
  console.log(from);

  const [notificationMessage, setNotificationMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const [formData, setFormData] = useState({
    usernameOrEmail: '',
    password: '',
  });

  // Check if the user is already authenticated
  if (auth?.token) {

    const token = localStorage.getItem("token");
    const expirationTime = localStorage.getItem("expiresIn");

    if (process.env.REACT_APP_ENV !== 'production') {
      console.log("Token from localStorage:", token);
      console.log("Expiration time from localStorage:", expirationTime);
      console.log("expirationTime " + expirationTime);
      const tmp = new Date(expirationTime);
      console.log("new Date exp obj" + tmp);
      const tmp1 = new Date()
      console.log("new Date obj" + tmp1);
    }

    console.log('process.env.REACT_APP_ENV ' + process.env.REACT_APP_ENV);

    if (token && expirationTime && new Date(expirationTime) > new Date()) {
      setAuth({ user: null, token });
      if (process.env.REACT_APP_ENV !== 'production') {
        console.log("User authenticated from localStorage");
      }
    }
    // Redirect to the "from" route or a default route if not specified
    navigate(from, { replace: true });
    login(auth); //DOUBLE CHECK THIS SOLUTION AFTER IMPLEMENT LOGOUT PIECE.
    // return null; // Don't render the login form
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.API_ENDPOINT}/users/login`, formData);

      // Assuming the response contains user data upon successful login
      const userData = response.data;

      // Update the authentication state by invoking the login function from the context
      login(userData);

      // setNotificationMessage('You have logged in successfully.'); //won't be accessed
      // setShowNotification(true);

      // Handle the response and navigate the user back to the original route
      navigate(from, { replace: true }); // Navigate to the previous "from" route

      // Handle the response and navigate the user, if needed
    } catch (error) {
      // Handle errors
      console.error('Login failed:', error);
      setNotificationMessage('An error occurred.');
      setShowNotification(true);

       // Reset the formData state to its initial state
       setFormData({
        usernameOrEmail: '',
        password: '',
      });

      setTimeout(() => {
        setShowNotification(false);
        }, 3000);

    }
  };

  // Function to handle input changes and update the state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={styles.loginContainer} style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className={styles.loginBox}>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className={styles.inputContainer}>
            {/* <label htmlFor="username">Username</label> */}
            <input
              type="text"
              id="usernameOrEmail"
              name="usernameOrEmail"
              required
              placeholder="Username or email"
              value={formData.usernameOrEmail}
              onChange={handleInputChange} // Capture usernameOrEmail input
              />
          </div>
          <div className={styles.inputContainer}>
            {/* <label htmlFor="password">Password</label> */}
            <input
              type="password"
              id="password"
              name="password"
              required
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange} // Capture password input
              />
          </div>
          <div className={styles.inputContainer}>
            <button type="submit">Log In</button>
          </div>
        </form>
        <div className={styles.forgotPassword}>
          <a href="/forgot-password">Forgot Your Password?</a>
        </div>
        <div className={styles.existingUser}>
          <p>New user? <a href="/sign-up">Sign Up</a></p>
        </div>
      </div>

      {showNotification && (
          <Notification
          message={notificationMessage}
          onClose={() => setShowNotification(false)}/>         
      )}

    </div>
  );
};

export default Login;