import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Library_Generic_Information.css';
import Loader from '../loader.js'; // Import the loader component
import config from '../../../config.js';

const BookAndAuthorCount = () => {
  const [loading, setLoading] = useState(true);
  const [bookCount, setBookCount] = useState(0);
  const [authorCount, setAuthorCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [bookResponse, authorResponse] = await Promise.all([
          axios.get(`${config.API_ENDPOINT}/books/count`),
          axios.get(`${config.API_ENDPOINT}/authors/count`),
        ]);

        // setTimeout(() => {
        //   setBookCount(bookResponse.data.count);
        //   setAuthorCount(authorResponse.data.count);
        //   setLoading(false);
        // }, 1000); // Delay to match the backend delay

        // Simulate progressive update of book count
        let currentBookCount = 0;
        const finalBookCount = bookResponse.data.count;
        const updateInterval = 10; // Milliseconds between updates

        const updateBookCount = () => {
          if (currentBookCount < finalBookCount) {
            setBookCount(currentBookCount);
            currentBookCount += 1;
            setTimeout(updateBookCount, updateInterval);
          } else {
            // When the final count is reached, update author count and finish loading
            setAuthorCount(authorResponse.data.count);
            setLoading(false);
          }
        };

        // Simulate progressive update of author count
        let currentAuthorCount = 0;
        const finalAuthorCount = authorResponse.data.count;

        const updateAuthorCount = () => {
          if (currentAuthorCount < finalAuthorCount) {
            setAuthorCount(currentAuthorCount);
            currentAuthorCount += 1;
            setTimeout(updateAuthorCount, updateInterval);
          }
        };

        // Start the progressive updates in parallel
        updateBookCount();
        updateAuthorCount();

        // Set loading to false after both counts are updated
        setLoading(false);

      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  if (loading) {
    return (
      // <div className='lib-img-background'>
      <div className='library-img-background'>

      {/* Photo by <a href="https://unsplash.com/@sylviasyang?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Sylvia Yang</a> on <a href="https://unsplash.com/photos/_ar2ENzmqb0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a> */}
  
        <Loader /> {/* Use the loader component */}
      </div>
    );
  }

  return (
    <div className='library-img-background'>
        <div className="text-count">
            <div>Books: {bookCount}</div>
            <div>Authors: {authorCount}</div>
      </div>
    </div>
  );
};

export default BookAndAuthorCount;