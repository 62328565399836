import React, {useState} from 'react';
import styles from './signUp.module.css';
import axios from 'axios';
// import useAuth from '../../hooks/useAuth.js';
import config from '../../../config.js';
import backgroundImage from '../../../images/library_white.jpg';
import { useNavigate  } from 'react-router-dom';

const SignUp = () => {

  // const { signUp } = useAuth(); // Use the login function from the context
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    password: ''
  });

  const hanldeSignUp = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${config.API_ENDPOINT}/users/register`, formData);

      if (response.status === 201) {
        navigate('/login'); // Redirect to the login page after successful sign-up
      } else {
        console.error('Unexpected status code:', response.status);
      }

    } catch (error) {

      console.error('SignUp failed:', error);

       // Reset the formData state to its initial state
       setFormData({
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        password: ''
      });

    }
  };

  // Function to handle input changes and update the state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={styles.loginContainer} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.loginBox}>
        <h2>Sign Up</h2>
        <form onSubmit={hanldeSignUp}>
          <div className={styles.inputContainer}>
            {/* <label htmlFor="username">Username</label> */}
            <input
              type="text"
              id="username"
              name="username"
              required
              placeholder="username"
              value={formData.username}
              onChange={handleInputChange} // Capture username input
              />
          </div>
          <div className={styles.inputContainer}>
            {/* <label htmlFor="email">email</label> */}
            <input
              type="text"
              id="email"
              name="email"
              required
              placeholder="email"
              value={formData.email}
              onChange={handleInputChange} // Capture email input
              />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="firstName"
              name="firstName"
            //   required
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
              />
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              id="lastName"
              name="lastName"
            //   required
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              />
          </div>
          <div className={styles.inputContainer}>
            {/* <label htmlFor="password">Password</label> */}
            <input
              type="password"
              id="password"
              name="password"
              required
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange} // Capture password input
              />
          </div>
          <div className={styles.inputContainer}>
            <button type="submit">Sign Up</button>
          </div>
        </form>
        <div className={styles.forgotPassword}>
          <a href="/forgot-password">Forgot Your Password?</a>
        </div>
        <div className={styles.existingUser}>
          <p>Existing user? <a href="/login">Sign in</a></p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;