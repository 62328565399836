import React from 'react';
import './siteInfo.css';

const SiteInfo = () => {
    return (
        <div className='site-img-background'>
            <div className="siteInfo">
                <p>About Me:</p>
                <p>
                   More info to be added soon.
                </p>
                <p>Websites I Like:</p>
                <ul>
                    <li>
                        <a href="https://www.example1.com" target="_blank" rel="noopener noreferrer">
                            Example Website 1
                        </a>
                    </li>
                    <li>
                        <a href="https://www.example2.com" target="_blank" rel="noopener noreferrer">
                            Example Website 2
                        </a>
                    </li>
                    <li>
                        <a href="https://www.example3.com" target="_blank" rel="noopener noreferrer">
                            Example Website 3
                        </a>
                    </li>
                </ul>
                <p>My Interests:</p>
                <p>
                    Placeholder
                </p>
                <p>Placeholder:</p>
                <p>
                Placeholder
                </p>
                <p>Placeholder:</p>
                <ul>
                    <li>Placeholder.</li>
                    <li>Placeholder.</li>
                    <li>Placeholder.</li>
                </ul>
            </div>
        </div>
    );
};

export default SiteInfo;
