import React from 'react';
import styles from './searchAuthorForm.module.css';
// import config from '../../../config.js';

export default function SearchAuthorForm() {
  // placeholder
  return (
    // placeholder
    <div className={styles.searchImg}>
      {/* <p className="image-credits">
      Image by <a href="https://pixabay.com/users/tuendebede-3187213/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2507902">Tünde</a> from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2507902">Pixabay</a>
      </p> */}
      {/* Form elements */}
        <form className="searchForm" action="/search">
          <input type="text" placeholder="Search.." name="search2" />
          <button type="submit">Search</button>
        </form>
      </div>
  );
}