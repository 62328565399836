import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../../config.js';

import styles from './authorList.module.css';

import '../../../App.css'; //To check if it is actually needed

const AuthorList = () => {
  const [books, setAuthors] = useState([]);

  useEffect(() => {
    axios.get(`${config.API_ENDPOINT}/authors`) // make the GET request to the endpoint
      .then(res => setAuthors(res.data))
      .catch(err => console.log(err));
  }, []);

  return (
    <div className={styles.tableContainer}>
      <h1 className={styles.bookHeader}>Books</h1>
       <table className={styles.table}>
          <thead>
            <tr>
              <th>Authors</th>
            </tr>
          </thead>
          <tbody id="bookTableBody"> 
          {/* 
          Author rows will be dynamically generated here */
          }
          {books.map(author => (
            <tr key={author._id}>
              <td>{author.name}</td>
            </tr>
          ))}
          </tbody>
        </table>
    </div>
  );
};

export default AuthorList;
