import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './addBookForm.module.css';
import Notification from '../../generic/notifications/notification.js';
import config from '../../../config.js'

export default function AddBookForm() {

    const [title, setTitle] = useState('');
    const [genre, setGenre] = useState('');
    const [publisher, setPublisher] = useState('');
    const [year, setYear] = useState('');
    const [keywords, setKeywords] = useState('');

    // Func for author autocompletion
    const [authorAutocomplete, setAuthorAutocomplete] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState({
      name: '', // The author's name
      _id: null, // The author's ID
    });

    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const createNewAuthor = async () => {
        try {
  
          // Create a new author object with the provided name
          const newAuthor = { 
            name: selectedAuthor.name,
            date_of_birth: null,
            date_of_death: null,
            ethnicity: "",
            imageUrl: "",
            bio: "",
           };
  
          // Send a POST request to create the new author
          const response = await axios.post(`${config.API_ENDPOINT}/authors`, newAuthor);
    
          // Update the selectedAuthor with the newly created author's name
          setSelectedAuthor(response.data.name);
    
          // Optionally, you can update the authorAutocomplete state to include the new author
          setAuthorAutocomplete([...authorAutocomplete, response.data]);

          setNotificationMessage('Author added successfully.');
          setShowNotification(true);

        } catch (error) {
          console.error('Error creating new author:', error);

          setNotificationMessage('Error creating new author.');
          setShowNotification(true);
        }

          setTimeout(() => {
          setShowNotification(false);
          }, 3000);
      };

      const fetchAuthors = async () => {
        try {
          const response = await axios.get(`${config.API_ENDPOINT}/authors`);
          const authors = response.data;
          setAuthorAutocomplete(authors);
        } catch (error) {
          console.error('Error fetching authors:', error);
        }
      };

    // Fetching list of existing authors, and populating the authorAutocomplete state once the component mounts:
    useEffect(() => {
        fetchAuthors();
      }, []);

    //   The handleSubmit function is an asynchronous function that uses axios to make the API call.
    //   The axios.post method sends a POST request to the /api/books endpoint with the newBook object as the request body.

    const handleBookAddition = async (e) => {
        e.preventDefault();

        // Create a new book object with the form data
        const newBook = {
        title,
        author: selectedAuthor._id,
        genre,
        publisher,
        year,
        keywords
        };

        try {
        // Make the API call to the backend using axios
        const response = await axios.post(`${config.API_ENDPOINT}/books`, newBook);

        // The response from the server is stored in the response variable, and we can handle the response data as needed. 
        // Here it logs the savedBook data to the console.

        const savedBook = response.data;
        console.log(savedBook); // Handle the response from the server if needed

        // Reset the form inputs
        setTitle('');
        setSelectedAuthor({
            name: '',
            _id: null,
          });
        setAuthorAutocomplete([]);
        setGenre('');
        setPublisher('');
        setYear('');
        setKeywords('');

        await fetchAuthors();

        setNotificationMessage('Book added successfully.');
        setShowNotification(true);
        
        } catch (error) {

            console.error(error);
            setNotificationMessage('An error occurred.');
            setShowNotification(true);
        }
            setTimeout(() => {
            setShowNotification(false);
            }, 3000);
    };

    return (
        <div className={styles.img}>
            <h2 className={styles.titleText}>Add Book</h2>
            
                {/* Title input */}
                <label>
                <p className={styles.textForm}>Title:</p>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </label>
                {/* Author input */}
                <label>
                <p className={styles.textForm}>Author:</p>
                <input
                    type='text'
                    list='author-options'
                    value={selectedAuthor.name}
                    // To capture changes made by selecting an author from the datalist [as well as manual changes] */}
                    onInput={(e) => {

                    const selectedAuthorId = authorAutocomplete.find(
                        (author) => author.name === e.target.value
                    )?._id;
                
                    setSelectedAuthor({
                        name: e.target.value,
                        _id: selectedAuthorId || null, // Use null if author not found
                    });

                    console.log("selectedAuthor.name: " + selectedAuthor.name);
                    console.log("selectedAuthor._id: " + selectedAuthor._id);

                    }}
                />

                {/* Datalist for autocompletion */}
                {/* The <datalist> tag specifies a list of pre-defined options for an <input> element.
                The <datalist> tag is used to provide an "autocomplete" feature for <input> elements. Users will see a drop-down list of pre-defined options as they input data.
                The <datalist> element's id attribute must be equal to the <input> element's list attribute (this binds them together). */}
                <datalist id='author-options'>
                    {/* Mapping through the authorAutocomplete array to generate options */}
                    {authorAutocomplete.map((author) => (
                    <option key={author._id} value={author.name} />
                    ))}
                </datalist>

                <input
                    type='hidden'
                    name='authorId'
                    value={selectedAuthor._id || ''}
                />
                </label>
                {/* Button to create a new author */}
                <button onClick={createNewAuthor} type="submit">Create New Author</button>
                {/* Genre input */}
                <label>
                <p className={styles.textForm}>Genre:</p>
                <input type="text" value={genre} onChange={(e) => setGenre(e.target.value)} />
                </label>
                {/* Publisher input */}
                <label>
                <p className={styles.textForm}>Publisher:</p>
                <input type="text" value={publisher} onChange={(e) => setPublisher(e.target.value)} />
                </label>
                {/* Year input */}
                <label>
                <p className={styles.textForm}>Year:</p>
                <input type="number" value={year} onChange={(e) => setYear(e.target.value)} />
                </label>
                {/* Keywords input */}
                <label>
                <p className={styles.textForm}>Keywords:</p>
                <input type="text" value={keywords} onChange={(e) => setKeywords(e.target.value)} />
                </label>
            <form onSubmit={handleBookAddition}> {/* onSubmit is an event handler */}
                {/* Submit button */}
                <button type="submit">Add Book</button>
            </form>

            {showNotification && (
                <Notification
                message={notificationMessage}
                onClose={() => setShowNotification(false)}/>         
            )}

        </div>
  );
}