import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Layout from "./generic/layout.js";
import React from 'react';

const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();

    if (process.env.REACT_APP_ENV !== 'production') {
        console.log("CHECK HERE:" + auth.token);
    }

    return (
        auth?.token
        ? <Layout />
        : <Navigate to="/login" state={{from: location}} replace />
    );
}

export default RequireAuth;