// import { BrowserRouter as Router, Route, Routes, Navigate, Outlet  } from 'react-router-dom';

import RequireAuth from './components/RequireAuth.js';
import {Routes, Route} from 'react-router-dom';
// import useAuth from './hooks/useAuth.js';

//Generic

import Layout from './components/generic/layout.js';
// import Sidebar from './components/generic/sidebar.js';
import BookCount from './components/generic/libraryGenericInformation/Library_Generic_Information.js';
import ContactForm from './components/generic/contactForm/contactForm.js';
import SiteInfo from './components/generic/siteInfo.js';
import Login from './components/generic/loginForm/login.js';
import SignUp from './components/generic/signUpForm/signUp.js';

//Book CRUD Forms

import Booklist from './components/bookForms/booklist/booklist.js';
import AddBookForm from './components/bookForms/addBookForm/addBookForm.js';
import SearchBookForm from './components/bookForms/searchBookForm/searchBookForm.js';
import DeleteBookForm from './components/bookForms/deleteBookForm/deleteBookForm.js';
import UpdateBookForm from './components/bookForms/updateBookForm/updateBookForm.js';

import BookDetails from './components/bookForms/bookDetails/bookDetails.js';

//Author CRUD Forms

import AuthorList from './components/authorForms/authorList/authorList.js';
import AddAuthorForm from './components/authorForms/addAuthorForm/addAuthorForm.js';
import SearchAuthorForm from './components/authorForms/searchAuthorForm/searchAuthorForm.js';
import DeleteAuthorForm from './components/authorForms/deleteAuthorForm/deleteAuthorForm.js';
import UpdateAuthorForm from './components/authorForms/updateAuthorForm/updateAuthorForm.js';

///////////////////

export default function App() {

  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<Layout />}/>
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />

        {/* Protected Routes */}
        <Route element={<RequireAuth/>}>

          <Route index element={<BookCount />} />
          <Route path="/home" element={<BookCount />} />
          <Route path="/about" element={<SiteInfo />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/books" element={<Booklist />} />
          <Route path="/authors" element={<AuthorList />} />
          <Route path="/book/:bookId" element={<BookDetails />} />

          <Route path="/add-book" element={<AddBookForm />} />
          <Route path="/search-book" element={<SearchBookForm />} />
          <Route path="/delete-book" element={<DeleteBookForm />} />
          <Route path="/update-book" element={<UpdateBookForm />} />
          <Route path="/add-author" element={<AddAuthorForm />} />
          <Route path="/search-author" element={<SearchAuthorForm />} />
          <Route path="/delete-author" element={<DeleteAuthorForm />} />
          <Route path="/update-author" element={<UpdateAuthorForm />} />
        </Route>
    
      </Routes>
    </div>
  )
}
