import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './deleteBookForm.module.css';
import config from '../../../config.js';

export default function DeleteBookForm() {
  
    const [books, setBooks] = useState([]);
    const [selectedBooks, setSelectedBooks] = useState([]);
  
    useEffect(() => {
      fetchBooks();
    }, []);
  
    const fetchBooks = async () => {
      try {
        const response = await axios.get(`${config.API_ENDPOINT}/books`);
        setBooks(response.data);
      } catch (error) {
        // Handle error
      }
    };

    // const numColumns = 3;
    // const columnSize = Math.ceil(books.length / numColumns);

    // const column1Books = books.slice(0, columnSize);
    // const column2Books = books.slice(columnSize, 2 * columnSize);
    // const column3Books = books.slice(2 * columnSize);
  
    const toggleBookSelection = (bookId) => {
      if (selectedBooks.includes(bookId)) {
        setSelectedBooks(selectedBooks.filter(id => id !== bookId));
      } else {
        setSelectedBooks([...selectedBooks, bookId]);
      }
    };
  
    const handleDeleteSelectedBooks = async () => {
      try {
        await axios.delete(`${config.API_ENDPOINT}/books`, {
          data: { bookIds: selectedBooks }, // Send selected book IDs for deletion
        });
        fetchBooks(); // Refresh books list after deletion
        setSelectedBooks([]); // Clear selected books
      } catch (error) {
        // Handle error
      }
    };
  
    return (
      <div className={styles.oldImg}>
      {/* <p className="image-credits">
      Image by <a href="https://pixabay.com/users/jarmoluk-143740/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=436498">Michal Jarmoluk</a> from <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=436498">Pixabay</a>
      </p> */}
        <table>
          <thead>
            <tr className={styles.titleText}>
              {Array.from({ length: 6 }, (_, index) => (
                <th key={index}>
                  {`Column ${index + 1}`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: Math.ceil(books.length / 6) }, (_, rowIndex) => (
              <tr key={rowIndex} className={styles.textRow}>
                {Array.from({ length: 6 }, (_, columnIndex) => {
                  const bookIndex = rowIndex * 6 + columnIndex;
                  const book = books[bookIndex];
                  return (
                    <td key={columnIndex}>
                      {/* Render a checkbox for each book entry */}
                      <input
                        type="checkbox"
                        checked={selectedBooks.includes(book?._id)}
                        onChange={() => toggleBookSelection(book?._id)}
                      />
                      {/* Display the book title */}
                      {book?.title || ''}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit" onClick={handleDeleteSelectedBooks}>Delete Selected Books</button>
      </div>

  )
};