import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import useAuth from '../../../hooks/useAuth.js';
import styles from './Logout_Button.module.css';

const LogoutButton = () => {
  const { logout } = useAuth();

  return (
    <div className={styles.logoutButton}>
      <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
        Log Out
      </button>
    </div>
  );
};

export default LogoutButton;