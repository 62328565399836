import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styles from './bookDetails.module.css';
import config from '../../../config.js'

export default function BookDetails() {

    const {bookId} = useParams(); // Get the bookId parameter from the route
    const [isLoading, setIsLoading] = useState(false); // Initialize isLoading with false

    const [book, setBook] = useState({}); // Initialize book with an empty object
  
    useEffect(() => {
        const fetchBookDetails = async () => {
          if (!isLoading) { // Check if a request is already in progress
            setIsLoading(true); // Set a loading state
            try {
              const response = await axios.get(`${config.API_ENDPOINT}/books/selected/${bookId}`);
              setBook(response.data);
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false); // Reset loading state
            }
          }
        };
      
        fetchBookDetails();
      }, [bookId]);
  
    return (
          <div className={styles.readerImg}>
            <div className={styles.titleText}>
              <h2>Title: {book.title || 'No title available'}</h2>
              <h2>Author: {book.author ? (book.author.name || 'No author available') : 'No author available'}</h2>
              <p>Genre: {book.genre || 'Unknown genre'}</p>
              <p>Publisher: {book.publisher || 'Unknown publisher'}</p>
              <p>Year: {book.year || 'Unknown year'}</p>
              <p>Keywords: {book.keywords || 'No keywords available'}</p>
            </div>
        </div>
    );
  }